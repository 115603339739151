import Reflux from "reflux";
import GitlabActions from "../actions/GitlabActions";
import LoginActions from "../actions/LoginActions";
import { dateToDayCode, dayDiff, parseGitlabId } from "../util";

export default class GitlabStore extends Reflux.Store {
  issueRelIds = {};
  noteRelIds = {};
  issueCounter = 0;
  noteCounter = 0;

  constructor() {
    super();

    this.state = {
      projects: [],
      issues: [],
      issueNotes: {},
      timeSpent: [],
    };

    this.listenables = [GitlabActions, LoginActions];
  }

  resetState() {
    this.issueRelIds = {};
    this.noteRelIds = {};
    this.issueCounter = 0;
    this.noteCounter = 0;

    this.setState({
      projects: [],
      issues: [],
      issueNotes: {},
      timeSpent: [],
    });
  }

  onResetData() {
    this.resetState();
  }

  onLogout() {
    this.resetState();
  }

  onProjectsLoad(projects) {
    this.setState({ projects: projects });
  }

  onIssueVisibilityToggled(issueId) {
    const issue = this.state.issues[issueId];
    if (issue) {
      issue.visible = !issue.visible;

      let visibleIssues = null;
      try {
        visibleIssues = JSON.parse(localStorage.getItem("visible_issues"));
      } catch (e) {}
      if (!visibleIssues) visibleIssues = {};

      visibleIssues[issue.absId] = issue.visible;
      try {
        localStorage.setItem("visible_issues", JSON.stringify(visibleIssues));
      } catch (e) {
        // ignore error (most likely on safari)
      }
    }
  }

  onIssueVisibilitySet(issueId, visibility) {
    const issue = this.state.issues[issueId];
    if (issue && issue.visible !== visibility) {
      this.onIssueVisibilityToggled(issueId);
      return true;
    }
    return false;
  }

  onMyProjectToggled(projectId) {
    let index = -1;

    for (let i = 0; i < this.state.projects.length; i++) {
      if (this.state.projects[i].id === projectId) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      this.state.projects[index].isMyProject = !this.state.projects[index].isMyProject;

      let myProjects = null;
      try {
        myProjects = JSON.parse(localStorage.getItem("my_projects"));
      } catch (e) {}

      if (!myProjects) myProjects = [];

      myProjects = myProjects.filter(
        (project) => project !== projectId
      );

      if (this.state.projects[index].isMyProject) {
        myProjects.push(projectId);
      }

      try {
        localStorage.setItem(
          "my_projects",
          JSON.stringify(myProjects)
        );
      } catch (e) {}
    }
  }

  getIssueRelId(issueAbsId) {
    let id = this.issueRelIds[issueAbsId];

    if (!id) {
      id = this.issueCounter++;
      this.issueRelIds[issueAbsId] = id;
    }

    return id;
  }

  getNoteRelId(noteAbsId) {
    let id = this.noteRelIds[noteAbsId];

    if (!id) {
      id = this.noteCounter++;
      this.noteRelIds[noteAbsId] = id;
    }

    return id;
  }

  /*
    issueData: {
      project: {
        id: string
        issues: {
          nodes: {
            ...,
            timelogs: {
              nodes:{
                spentAt: string
                timeSpent: string
              }[]
            }
          }[]
        }
      }
      pageInfo:{
        endCursor: string
      }
    }
  */
  onIssuesLoad(issueData, username) {
    // todo
    // tohle je trochu podezrele - funguje to jinak nez stary system; ten vzdy pri zacatku nacitani projektu
    // vymazal ze statu vsechny issues daneho projektu (obdobne pak notes-issues pri nacitani issues)
    // mozna to bylo zbytecne? i kdyby ne, neni to v novem systemu uplne zbytecne? notes i issues nacitam
    // spolecne, a vzdy vsechny z prazdneho stavu (vyjimka: timeAdded - zvlastni pripad co by to ale nemel ovlivnit)
    const _issues = this.state.issues;
    const _issueNotes = this.state.issueNotes;

    const projectId = parseGitlabId(issueData.project.id);

    let visibleIssues = null;
    try {
      visibleIssues = JSON.parse(localStorage.getItem("visible_issues"));
    } catch (e) {}

    if (!visibleIssues) visibleIssues = {};

    const issues = issueData.project.issues.nodes;
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];
      const issueAbsId = `${projectId}_${issue.iid}`;
      const issueId = this.getIssueRelId(issueAbsId);
      const issueIid = parseInt(issue.iid);

      let assignee = "";

      if (issue.assignees.nodes.length === 1) {
        assignee = issue.assignees.nodes[0].username;
      }

      let visible = false;

      if (visibleIssues[issueAbsId] !== undefined) {
        // issue visibility was set by user before - use it
        visible = visibleIssues[issueAbsId];
      } else {
        // try to guess whether to show the issue:

        // only if the issue is not closed
        if (!issue.closedAt) {
          // show it if the user is assigned to it
          visible = assignee === username;

          // show it if it was updated in the last 10 days
          if (!visible) {
            const today = new Date();
            const updated = new Date(issue.updatedAt);
            const delta = dayDiff(updated, today);
            visible = delta <= 10;
          }
        }
      }

      const isIssueClosed =
        issue.closedAt !== null && issue.closedAt !== undefined;

      _issues[issueId] = {
        ...issue,
        assignee: assignee,
        id: issueId,
        iid: issueIid,
        absId: issueAbsId,
        project: projectId,
        title: issue.title,
        visible: visible,
        closed: isIssueClosed,
      };

      const notes = issue.notes.nodes;
      for (let j = 0; j < notes.length; j++) {
        const note = notes[j];
        //const noteId = this.getNoteRelId(`${issueAbsId}_${parseGitlabId(note.id)}`);
        const noteId = `${issueAbsId}_${parseGitlabId(note.id)}`;
        const author = note.author.username;
        if (author === username) {
          _issueNotes[noteId] = {
            author_username: author,
            id: noteId,
            body: note.body,
            project: projectId,
            issue: issueIid,
          };
        }
      }
    }
    this.setState({ openedIssues: _issues });
    this.recalculateTimeSpent(username);
  }

  recalculateTimeSpent(username) {
    // timespent[project.id][issue.iid][timestamp]

    const newTimeSpent = [];
    const processIssue = (issue) => {
      try {
        const { iid, project } = issue;
        const timelogs = issue.timelogs.nodes.filter(
          ({ user }) => user.username === username
        );

        timelogs.forEach(({ timeSpent, spentAt }) => {
          const dayCode = dateToDayCode(new Date(spentAt));
          const timeToAdd = timeSpent / (60 * 60);
          if (!newTimeSpent[project]) newTimeSpent[project] = [];

          const issueIndex = iid;

          if (!newTimeSpent[project][issueIndex])
            newTimeSpent[project][issueIndex] = {};

          if (!newTimeSpent[project][issueIndex][dayCode])
            newTimeSpent[project][issueIndex][dayCode] = 0;

          newTimeSpent[project][issueIndex][dayCode] += timeToAdd;
        });
      } catch (e) {
        // ignore unknown error - possibly user comment that doesn't match expected format
      }
    };

    const issues = this.state.openedIssues;
    issues.forEach(processIssue);
    this.setState({ timeSpent: newTimeSpent });
  }
}
