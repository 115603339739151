import React from "react";
import ProjectListItem from "./ProjectListItem";
import Table from "react-bootstrap/Table";
import { dateRange, dateToDayCode, formatHours } from "../../../../util";

export default class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.dummyDivWrapper = React.createRef();
    this.tableHead = React.createRef();
    this.dummyDiv = React.createRef();
  }

  totalDayHours(day, counter, filteredIssues, filteredProjects) {
    const filteredIssuesIds = filteredIssues.map((issue) => issue.id);
    const timeSpent = this.props.timeSpent;
    const dayCode = dateToDayCode(day);
    let rtn = 0;

    if (timeSpent) {
      const visibleProjects = [];

      filteredProjects.forEach((project) => {
        if (project.isMyProject) visibleProjects.push(project.id);
      });

      this.props.issues.forEach((issue) => {
        if (
          visibleProjects.indexOf(issue.project) !== -1 &&
          issue.visible &&
          filteredIssuesIds.indexOf(issue.id) !== -1
        ) {
          if (
            !timeSpent ||
            !timeSpent[issue.project] ||
            !timeSpent[issue.project][issue.iid] ||
            !timeSpent[issue.project][issue.iid][dayCode]
          )
            return;
          rtn += timeSpent[issue.project][issue.iid][dayCode];
        }
      });

      visibleProjects.forEach((project) => {
        if (
          !(
            timeSpent[project] &&
            timeSpent[project][0] &&
            timeSpent[project][0][dayCode]
          )
        )
          return;

        rtn += timeSpent[project][0][dayCode];
      });

      counter.counter += rtn;
    }

    return rtn;
  }

  issuesFilterFun(issue) {
    const isIssueSearch = !!this.props.filterText.length;
    const baseCondition = issue.visible;
    const [isShowOpenneedIssues, showOpenneedIssuesCondition] = [
      this.props.filterOpenedIssues,
      !issue.closed && baseCondition,
    ];
    const [isShowUsersIssues, showUsersIssuecCondition] = [
      this.props.filterUserIssues,
      issue.assignee === this.props.username && baseCondition,
    ];
    if (isIssueSearch) {
      return (
        `#${issue.iid} ${issue.title}`
          .toLowerCase()
          .indexOf(this.props.filterText.toLowerCase()) !== -1
      );
    }

    if (isShowOpenneedIssues) {
      if (isShowUsersIssues) {
        return showOpenneedIssuesCondition && showUsersIssuecCondition;
      }
      return showOpenneedIssuesCondition;
    }

    if (isShowUsersIssues) {
      return showUsersIssuecCondition;
    }

    return baseCondition;
  }

  componentDidUpdate() {
    // Setting upper scrollbar
    const dummyDivWrapper = this.dummyDivWrapper.current;
    const tableHead = this.tableHead.current; // Just to read the width of the actual table
    const dummyDiv = this.dummyDiv.current;

    // Element rendered by bootstrap - needs to by found by class since it has no id
    let tableResponsive = document.getElementsByClassName('table-responsive');

    if (dummyDivWrapper !== null && tableResponsive !== null && dummyDiv !== null && tableResponsive.length !== 0) {
      tableResponsive = tableResponsive[0];

      // If the table fits the content, hide the scrollbar
      if (tableHead.clientWidth <= tableResponsive.clientWidth) {
        dummyDivWrapper.style.display = "none";
        return;
      }

      // Show the scrollbar
      dummyDivWrapper.style.display = "block";

      dummyDivWrapper.onscroll = () => tableResponsive.scrollLeft = dummyDivWrapper.scrollLeft;
      tableResponsive.onscroll = () => dummyDivWrapper.scrollLeft = tableResponsive.scrollLeft;

      // DummyDiv needs to have the same width as the actual table so that the two scrollbars look the same
      dummyDiv.style.width = tableHead.clientWidth + "px";
    }
  }

  render() {
    const filteredIssues = this.props.issues.filter((issue) =>
      this.issuesFilterFun(issue)
    );
    const projects = this.props.projects
      .filter(
        (project) =>
          filteredIssues.filter((issue) => issue.project === project.id)
            .length > 0
      )
      .filter((project) => project.isMyProject);

    const days = dateRange(this.props.startDate, this.props.endDate);
    const today = new Date();

    const dayClassnames = (day) => {
      let rtn = " ";

      if (
        today.getFullYear() === day.getFullYear() &&
        today.getMonth() === day.getMonth() &&
        today.getDate() === day.getDate()
      )
        rtn += "today ";
      else if (day.getDay() % 6 === 0) rtn += "weekend ";

      return rtn;
    };

    let totalCounter = { counter: 0 };

    return (
      <div className="project-table-container">
          <div id="dummy-div-wrapper" ref={this.dummyDivWrapper}>
              <div id="dummy-div" ref={this.dummyDiv}/>
          </div>
        <Table id="project-table" size="sm" responsive className="project-table">
          <thead id="table-head" ref={this.tableHead}>
            <tr>
              <td className="col-sm-6" />
              {days.map((day) => (
                <td
                  key={day.getTime()}
                  className={"issue-time-cell" + dayClassnames(day)}
                >
                  <div className="date">
                    {day.getDate()}.{day.getMonth() + 1}.
                  </div>
                </td>
              ))}
              <td className="time-sum-cell">&Sigma;</td>
            </tr>

            <tr className="date">
              <td className="text-right">Celkový součet</td>
              {days.map((day) => (
                <td
                  key={day.getTime()}
                  className={"issue-time-cell" + dayClassnames(day)}
                >
                  {formatHours(
                    this.totalDayHours(
                      day,
                      totalCounter,
                      filteredIssues,
                      projects
                    ),
                    true
                  )}
                </td>
              ))}

              <td className="text-center">
                {formatHours(totalCounter.counter, true)}
              </td>
            </tr>
          </thead>

          <tbody>
            {projects.map((project) => (
              <ProjectListItem
                key={project.id}
                project={project}
                issues={filteredIssues
                  .filter((issue) => issue.project === project.id)
                  .reverse()}
                days={days}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                openNewItem={this.props.openNewItem}
                timeSpent={
                  this.props.timeSpent[project.id]
                    ? this.props.timeSpent[project.id]
                    : []
                }
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
