const felGitlabBasePath = "https://gitlab.fel.cvut.cz/";

export function projectPathUrl(projectPath) {
    return felGitlabBasePath + projectPath;
}

export function issuePathUrl(projectPath, issueIid) {
    return `${projectPathUrl(projectPath)}/issues/${issueIid}`;
}

export function dayDiff(start, end) {
    if (start > end) {
        const tmp = end;
        end = start;
        start = tmp;
    }

    return Math.round((end - start) / (1000 * 3600 * 24)) + 1;
}

// POZOR, pokud tohle posilame nekam, kde je norma s mesicem zacinajicim od 1, tak implicitne timto prevodem odecitame mesic!
// Implicitni odecitani mesice je problematicke, protoze to v lednu da nevalidni datum!
export function dateToDayCode(date) {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

export function floorFloat(float, places = 2, decimalSeparator) {
    if (!float) {
        float = 0;
    }

    const coef = 10 ** places;
    let rtn = Number(Math.floor(float * coef)/coef).toFixed(places);

    if (decimalSeparator && decimalSeparator !== ".") {
        rtn = rtn.replace(".", ",");
    }

    return rtn;
}

export function dateRange(startDate, endDate) {
    if (endDate < startDate) {
        const tmp = endDate;
        endDate = startDate;
        startDate = tmp;
    }

    const days = [];

    for (let i = 0; ; i++) {
        const date = new Date(startDate.getTime());
        date.setDate(startDate.getDate() + i);
        days.push(date);

        if (date >= endDate)
            break;
    }

    return days;
}

export function parseTimeExpression(text) {
    let rtn = false;

    const addedRegex = /^added (.*) of time spent at (\d{4}-\d{2}-\d{2})$/;
    const removedRegex = /^subtracted (.*) of time spent at (\d{4}-\d{2}-\d{2})$/;
    let sign = 1;
    let expressionParts = addedRegex.exec(text);

    if (expressionParts === null) {
        sign = -1;
        expressionParts = removedRegex.exec(text);
    }

    if (expressionParts && expressionParts.length === 3) {
        let hoursToAdd = 0;
        const timeRegex = /(\d*mo)? ?(\d*w)? ?(\d*d)? ?(\d*h)? ?(\d*m)? ?(\d*s)?/;

        const dateExpression = expressionParts[2];
        const timeExpression = timeRegex.exec(expressionParts[1]);

        if (timeExpression) {
            timeExpression[0] = null;

            timeExpression.forEach(part => {
                if (!part)
                    return;

                const quantifier = part.substr(-1);
                let value = parseFloat(part.substr(0, part.length - 1));

                switch (quantifier) {
                    case "o":
                        // gitlab: 1mo = 4w
                        value *= (8 * 5 * 4);
                        break;

                    case "w":
                        // gitlab: 1w = 5d
                        value *= (8 * 5);
                        break;

                    case "d":
                        // gitlab: 1d = 8h
                        value *= 8;
                        break;

                    case "h":
                        break;

                    case "m":
                        value /= 60;
                        break;

                    case "s":
                        value /= (60 * 60);
                        break;

                    default:
                        value = 0;
                        break;
                }

                hoursToAdd += value;
            });

            rtn = {
                timeSpent: hoursToAdd * sign,
                date: new Date(dateExpression)
            }
        }
    }

    return rtn;
}

export function formatHours(hours, forceDisplay = false) {
    if ((hours < 0.01 || !hours) && !forceDisplay) {
        return "";
    }

    return `${floorFloat(hours, 2)} h`;
}

export function parseGitlabId(idString) {
    if (!idString)
        return -1;
    const id = idString.split("/");
    return parseInt(id[id.length-1]);
}