import React from "react";
import Reflux from "reflux";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import LoginActions from "../../actions/LoginActions";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import cs from "date-fns/locale/cs";
import LoadingOverlay from "react-loading-overlay";
import TokenStore from "../../stores/TokenStore";
import Gitlab from "../../gitlab";
import { dayDiff, floorFloat, parseGitlabId } from "../../util";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv";
import LoginInfo from "../components/LoginInfo";

export default class ReportPage extends Reflux.Component {
  /**
   *
   * @type {Gitlab}
   */
  gitlab = null;

  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),

      loading: false,
      cancelled: false,
      data: [],

      reportStart: null,
      reportEnd: null,
    };

    this.stores = [TokenStore];
  }

  componentDidMount() {
    this.gitlab = new Gitlab(this.state.gitlabToken);
    this.showMonth();
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.gitlab.halt();
  }

  cancelLoading() {
    this.setState({
      cancelled: true,
    });

    this.gitlab.halt();
  }

  createReport() {
    this.setState({ loading: true, cancelled: false });
    this.gitlab.halt();
    const data = [];

    const startDate = new Date(this.state.startDate.getTime());
    const today = new Date();

    const minDate = new Date(this.state.startDate.getTime());
    const maxDate = new Date(this.state.endDate.getTime());
    minDate.setHours(0, 0, 0, 0);
    maxDate.setHours(23, 59, 59, 0);

    const finish = () => {
      if (!this.state.cancelled) {
        this.setState({
          loading: false,
          data: data,
          reportStart: minDate,
          reportEnd: maxDate,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    };

    if (startDate > today) {
      finish();
      return;
    }

    startDate.setDate(startDate.getDate() - 2);

    const reportConfig = {
      minDate,
      maxDate,
      startDate,
      canceleld: this.state.cancelled,
    };

    this.gitlab.loadReportData(data, reportConfig).then(finish);
  }

  computeInternSums() {
    const interns = [];
    const data = this.state.data;

    data.forEach((entry) => {
      const internId = parseGitlabId(entry.author_id);

      if (!interns[internId]) {
        interns[internId] = { name: entry.author_username, time_spent: 0 };
      }

      interns[internId].time_spent += entry.time_spent;
    });

    return interns;
  }

  createCsv() {
    if (this.state.data.length < 1) return null;

    const data = this.state.data.map((entry) => {
      return [
        entry.author_username,
        entry.project_name,
        entry.issue_title,
        `${entry.date.getDate()}.${
          entry.date.getMonth() + 1
        }.${entry.date.getFullYear()}`,
        entry.time_spent,
      ];
    });

    data.unshift([
      "Stážista",
      "Projekt",
      "Issue",
      "Datum",
      "Odpracované hodiny",
    ]);

    return (
      <CSVLink
        data={data}
        className="btn btn-sm btn-success"
        filename="report.csv"
      >
        <FontAwesomeIcon icon={faDownload} />
        Uložit podrobný report
      </CSVLink>
    );
  }

  showMonth() {
    let today = new Date();
    today = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    );

    let start, end;
    let dom = today.getDate();

    if (dom < 18) {
      start = new Date(today.getTime());
      start.setMonth(start.getMonth() - 1);
      start.setDate(18);

      end = new Date(today.getTime());
      end.setDate(17);
    } else {
      start = new Date(today.getTime());
      start.setDate(18);

      end = new Date(today.getTime());
      end.setDate(17);
      end.setMonth(end.getMonth() + 1);
    }

    this.setState({ startDate: start, endDate: end });
  }

  previousTimePeriod() {
    this.nextTimePeriod(-1);
  }

  nextTimePeriod(sign = 1) {
    let start = this.state.startDate,
      end = this.state.endDate;

    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    if (
      start.getDate() === end.getDate() ||
      (start.getDate() === 19 && end.getDate() === 18)
    ) {
      // special case: month range
      const delta = sign;
      start.setMonth(start.getMonth() + delta);
      end.setMonth(end.getMonth() + delta);
    } else {
      //default case: day range
      const delta = dayDiff(start, end) * sign;
      start.setDate(start.getDate() + delta);
      end.setDate(end.getDate() + delta);
    }

    this.setState({ startDate: start, endDate: end });
  }

  render() {
    const internSums = this.computeInternSums();
    const csvExport = this.createCsv();
    const showReport = this.state.reportStart !== null;

    return (
      <div className="dashboard">
        <h1>TimesUP! - Reporty</h1>

        <div className="header">
          <div>
            <h5>Akce</h5>

            <Button
              variant="info"
              size="sm"
              onClick={() => LoginActions.onViewChange("dashboard")}
            >
              <FontAwesomeIcon icon={faTable} />
              Vykazování
            </Button>
          </div>

          <div className="report-settings">
            <h5>Report</h5>

            <div className="inline-form-group">
              <Form.Label htmlFor="start-date">Od</Form.Label>
              <DatePicker
                id="start-date"
                className="form-control date-input"
                locale={cs}
                dateFormat="d.M.yyyy"
                selected={this.state.startDate}
                onChange={(date) => this.setState({ startDate: date })}
              />
            </div>

            <div className="inline-form-group">
              <Form.Label htmlFor="end-date">Do</Form.Label>
              <DatePicker
                id="end-date"
                className="form-control date-input"
                locale={cs}
                dateFormat="d.M.yyyy"
                selected={this.state.endDate}
                onChange={(date) => this.setState({ endDate: date })}
              />
            </div>

            <div className="inline-form-group">
              <Button
                className="icon-button"
                variant="dark"
                size="sm"
                onClick={() => this.previousTimePeriod()}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button
                className="icon-button"
                variant="dark"
                size="sm"
                onClick={() => this.nextTimePeriod()}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => this.showMonth()}
              >
                Aktuální měsíc
              </Button>
            </div>

            <div className="mt-10">
              <Button
                variant="primary"
                size="sm"
                onClick={() => this.createReport()}
              >
                <FontAwesomeIcon icon={faCheck} />
                Vytvořit report
              </Button>
            </div>
          </div>
        </div>

        {showReport ? (
          <div className="report-table-container">
            <h5>
              Report za období&nbsp;
              <strong>
                {`${this.state.reportStart.getDate()}.${
                  this.state.reportStart.getMonth() + 1
                }.${this.state.reportStart.getFullYear()}`}
                &nbsp;-&nbsp;
                {`${this.state.reportEnd.getDate()}.${
                  this.state.reportEnd.getMonth() + 1
                }.${this.state.reportEnd.getFullYear()}`}
              </strong>
            </h5>

            {this.state.data.length > 0 ? (
              <div>
                <Table
                  size="sm"
                  striped
                  bordered
                  hover
                  className="report-table table-fit mt-15"
                >
                  <thead>
                    <tr>
                      <th>Stážista</th>
                      <th className="text-right">Odpracovaný čas (hod.)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {internSums
                      .sort((a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                      )
                      .map((intern, id) => {
                        return (
                          <tr key={id}>
                            <td>{intern.name}</td>
                            <td className="text-right">
                              {floorFloat(intern.time_spent, 2, ",")}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                {csvExport}
              </div>
            ) : (
              <div>Ve vybraném období nebyly nalezeny žádné záznamy</div>
            )}
          </div>
        ) : null}

        {this.state.loading ? (
          <LoadingOverlay
            active={true}
            className="loader"
            spinner
            text={
              <div>
                <div>
                  {this.state.cancelled
                    ? "Zastavuji generování reportu..."
                    : ["Probíhá generování reportu...", <br/> ,"Skoč si mezitím na kafe ;)"]}
                </div>
                <div>
                  {this.state.cancelled ? null : (
                    <span
                      className="pseudolink"
                      onClick={() => this.cancelLoading()}
                    >
                      Zrušit
                    </span>
                  )}
                </div>
              </div>
            }
          />
        ) : null}

        <LoginInfo />
      </div>
    );
  }
}
