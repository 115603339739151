import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {dateToDayCode, formatHours, issuePathUrl} from "../../../../util";

export default class ProjectListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        };
    }

    componentDidMount() {
        let savedCollapses = null;
        try {
            savedCollapses = JSON.parse(localStorage.getItem('collapsed_projects'));
        } catch (e) {
        }

        if (savedCollapses && savedCollapses[this.props.project.id])
            this.setState({collapsed: true});
    }

    toggleCollapse() {
        const collapsed = !this.state.collapsed;
        this.setState({collapsed: collapsed});

        let savedCollapses = null;
        try {
            savedCollapses = JSON.parse(localStorage.getItem('collapsed_projects'));
        } catch (e) {
        }

        if (!savedCollapses)
            savedCollapses = {};

        savedCollapses[this.props.project.id] = collapsed;
        try {
            localStorage.setItem('collapsed_projects', JSON.stringify(savedCollapses));
        } catch (e) {
            // ignore error (most likely on safari)
        }
    }

    issueHours(day, issue, counter = null) {
        const timeSpent = this.props.timeSpent;
        const dayCode = dateToDayCode(day);
        let rtn = 0;

        if (timeSpent && timeSpent[issue.iid] && timeSpent[issue.iid][dayCode])
            rtn = timeSpent[issue.iid][dayCode];

        if (counter) {
            if (!counter.issue) {
                counter.issue = issue;
            } else if (counter.issue !== issue) {
                counter.counter = 0;
                counter.issue = issue;
            }
            counter.counter += rtn;
        }

        return rtn;
    }

    projectHours(day, counter) {
        const timeSpent = this.props.timeSpent;
        const dayCode = dateToDayCode(day);
        let rtn = 0;

        if (timeSpent) {
            timeSpent.forEach((time, issueIid) => {
                const i = this.props.issues.filter(i => i.iid === issueIid);
                if (time && time[dayCode] && i && i.length === 1)
                    rtn += time[dayCode]
            });

            if (timeSpent[0] && timeSpent[0][dayCode])
                rtn += timeSpent[0][dayCode];
        }

        if (counter) {
            counter.counter += rtn;
        }

        return rtn;
    }

    render() {
        const days = this.props.days;
        const caret = this.state.collapsed ? faCaretRight : faCaretDown;
        const today = new Date();

        const dayClassnames = day => {
            let rtn = " ";

            if (day > today)
                rtn += "no-add ";

            if (today.getFullYear() === day.getFullYear() &&
                today.getMonth() === day.getMonth() &&
                today.getDate() === day.getDate()) {
                rtn += "today ";
            }
            else if (day.getDay()%6 === 0)
                rtn += "weekend ";

            return rtn;
        };

        let counter = {counter: 0};
        const projectName =
            <tr className="project-row" key={this.props.project.id}>
                <td onClick={() => this.toggleCollapse()}>
                    <FontAwesomeIcon icon={caret} className="mr-2"/> {this.props.project.name}
                </td>

                {
                    days.map(day =>
                        <td key={day.getTime()} className={"issue-time-cell" + dayClassnames(day)}
                            onClick={day <= today ? () => this.props.openNewItem(this.props.project, null, day, false, true) : null}>

                            {
                                day <= today ?
                                    <div className="add-time">
                                        <FontAwesomeIcon icon={faPlus} size="lg"/>
                                    </div> : null
                            }

                            <div className="time">
                                {formatHours(this.projectHours(day, counter), true)}
                            </div>
                        </td>
                    )
                }

                <td className="time-sum-cell">
                    {formatHours(counter.counter, true)}
                </td>
            </tr>;

        counter = {counter: 0};
        const issues = this.state.collapsed ? null : this.props.issues.map(issue =>
            <tr key={issue.id}>
                <td>
                    <a href={issuePathUrl(this.props.project.path, issue.iid)}
                       className="gitlab-issue-link"
                       target="_blank"
                       rel="noopener noreferrer"
                       title="Otevřít issue v Gitlabu"
                    >
                        <span className="issue-iid">#{issue.iid}:</span>
                        <span className="issue-title">{issue.title}</span>
                    </a>
                </td>

                {
                    days.map(day =>
                        <td key={day.getTime()} className={"issue-time-cell" + dayClassnames(day)}
                            onClick={day <= today ? () => this.props.openNewItem(this.props.project, issue, day, true, false) : null}>

                            {
                                day <= today ?
                                    <div className="add-time">
                                        <FontAwesomeIcon icon={faPlus} size="lg"/>
                                    </div> : null
                            }

                            <div className="time">
                                {formatHours(this.issueHours(day, issue, counter))}
                            </div>
                        </td>
                    )
                }

                <td className="time-sum-cell">
                    {formatHours(counter.counter, true)}
                </td>
            </tr>
        );

        return (
            [projectName, issues]
        );
    }
}