import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default class CloseButton extends React.Component {
    render() {
        return (
            <span className="dialog-close-button" onClick={this.props.onClick}>
                <FontAwesomeIcon icon={faTimes}/>
            </span>
        );
    }
}