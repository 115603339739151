import { dateToDayCode } from "../util";

export default class Gitlab {
  debug = false;
  baseUrl = "https://gitlab.fel.cvut.cz/api/v4";
  graphqlUrl = "https://gitlab.fel.cvut.cz/api/graphql";
  gitlabToken = null;
  haltBarrier = 0;

  requests = [];

  constructor(gitlabToken) {
    this.gitlabToken = gitlabToken;

    try {
      const url = new URL(window.location.href);
      if (
        url.hostname === "localhost" &&
        url.searchParams.get("devel") === "true"
      ) {
        this.debug = true;
      }
    } catch (e) { }
  }

  halt() {
    this.haltBarrier++;
    this.requests.forEach((request) => request.abort());
  }

  ajaxRequest(method, target, payload) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      this.requests.push(request);

      request.addEventListener("load", (response) => {
        this.requests = this.requests.filter((item) => item !== request);

        if (request.status >= 400) reject(response.target.responseText);

        resolve(response.target.responseText);
      });

      request.addEventListener("error", (error) => {
        this.requests = this.requests.filter((item) => item !== request);

        reject(error.target);
      });

      request.addEventListener("abort", () => {
        this.requests = this.requests.filter((item) => item !== request);
        reject();
      });

      request.open(method, target);
      request.send(payload);
    });
  }

  graphqlRequest(query) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      // Send requests delayed based on queue size
      // bcs GL has hard limit of 30s for request processing,
      // and without this it'll just DDoS the server... :facepalm:
      const delay = Math.max(0, this.requests.length - 35) * 400;
      this.requests.push(request);
      setTimeout(() => {
        console.log("Delayed by ", delay, "scheduled request count", this.requests.length)
  
        request.addEventListener("load", (response) => {
          this.requests = this.requests.filter((item) => item !== request);
  
          if (request.status >= 400) reject(response.target.responseText);
          const data = JSON.parse(response.target.responseText);
          resolve(data.data);
        });
  
        request.addEventListener("error", (error) => {
          this.requests = this.requests.filter((item) => item !== request);
  
          reject(error.target);
        });
  
        request.addEventListener("abort", () => {
          this.requests = this.requests.filter((item) => item !== request);
          reject();
        });
  
        request.open("POST", this.graphqlUrl);
        request.setRequestHeader("content-type", "application/json");
        request.send(
          JSON.stringify({
            operationName: null,
            query: query,
            private_token: this.gitlabToken,
            variables: null,
          })
        );
      }, delay)
    });
  }

  apiUrl(method) {
    if (method.substr(0, 1) !== "/") method = `/${method}`;

    const timestamp = new Date().getTime();

    return method.indexOf("?") === -1
      ? `${this.baseUrl}${method}?private_token=${this.gitlabToken}&nocache=${timestamp}`
      : `${this.baseUrl}${method}&private_token=${this.gitlabToken}&nocache=${timestamp}`;
  }

  async getPagedContent(url, onPageLoad, itemsPerPage = 25) {
    const continueBarrier = this.haltBarrier;

    itemsPerPage = Math.min(100, Math.max(1, itemsPerPage));

    const getPageData = (page = 1) => {
      return new Promise((resolve, reject) => {
        this.ajaxRequest("GET", `${url}&per_page=${itemsPerPage}&page=${page}`)
          .then((response) => {
            resolve(JSON.parse(response));
          })
          .catch(() => {
            resolve([]);
          });
      });
    };

    for (let page = 1; ; page++) {
      const pageData = await getPageData(page);

      onPageLoad(pageData);

      if (
        pageData.length === 0 ||
        pageData.length < itemsPerPage ||
        this.haltBarrier > continueBarrier
      )
        break;
    }
  }

  async getGroupProjects(group) {
    const projectsEndpoint = this.apiUrl(`/groups/${group}/projects`);

    const projects = [];
    const onLoad = (data) => {
      data.forEach((project) => projects.push(project));
    };

    if (this.debug) {
      await this.getPagedContent(
        this.apiUrl(`/projects?search=test&owned=true`),
        (data) => {
          data.forEach((project) => projects.push(project));
        },
        100
      );
    } else {
      await this.getPagedContent(projectsEndpoint, onLoad, 100);
    }

    return projects;
  }

  async getGroupSubgroups(group) {
    if (this.debug) {
      return [];
    }

    const subgroupsEndpoint = this.apiUrl(`/groups/${group}/subgroups`);

    const groups = [];
    const onLoad = (data) => {
      data.forEach((group) => groups.push(group));
    };

    await this.getPagedContent(subgroupsEndpoint, onLoad);

    return groups;
  }

  async getAllGroupProjects(group) {
    const subgroups = await this.getGroupSubgroups(group);

    let myProjects = null;
    try {
      myProjects = JSON.parse(localStorage.getItem("my_projects"));
    } catch (e) { }

    if (!myProjects) myProjects = [];

    const loadProjects = async (subgroups) => {
      const projects = [];

      for (let i = 0; i < subgroups.length; i++) {
        const subgroup = subgroups[i];
        const subgroupProjects = await this.getAllGroupProjects(subgroup.id);
        subgroupProjects.forEach((project) => projects.push(project));
      }

      (await this.getGroupProjects(group)).forEach((groupProject) => {
        const isMyProject = myProjects.indexOf(groupProject.id) !== -1;

        projects.push({
          id: groupProject.id,
          name: groupProject.name_with_namespace,
          path: groupProject.path_with_namespace,
          isMyProject: isMyProject
        });
      });

      return projects;
    };
    return loadProjects(subgroups);
  }

  async getAllIssues(projectId, fromDate, onIssuesLoad) {
    const issuesEndpoint = this.apiUrl(
      `/projects/${projectId}/issues?updated_after=${fromDate.toISOString()}`
    );
    const onLoad = (data) => {
      onIssuesLoad(
        data.map((issue) => {
          return {
            id: issue.id,
            iid: issue.iid,
            title: issue.title,
            project: projectId,
          };
        })
      );
    };

    await this.getPagedContent(issuesEndpoint, onLoad, 100);
  }

  async getUserInfo() {
    let userInfo = null;

    await this.ajaxRequest("GET", this.apiUrl("/user")).then(
      (response) => (userInfo = JSON.parse(response))
    );

    return userInfo;
  }

  async getAllTimelogsForIssue(projectPath, issueId) {
    const timelogs = [];

    const getTimelogsQuery = (issueIid) =>
      `{project(fullPath: "${projectPath}") {
        issue(iid: "${issueIid}") {
          timelogs(first: 100) {
            nodes {
              spentAt
              timeSpent
              user {
                id
                name
                username
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
      }}}`;

    const moreTimelogsQuery = (issueIid, cursor) =>
      `{project(fullPath: "${projectPath}") {
        issue(iid: "${issueIid}") {
          timelogs(first: 100, after: "${cursor}") {
            nodes {
              spentAt
              timeSpent
              user {
                id
                name
                username
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
      }}}`;

    let timelogsPageInfo = await this.graphqlRequest(
      getTimelogsQuery(issueId)
    ).then((data) => {
      const localTimelogs = data.project.issue.timelogs.nodes;
      for (let i = 0; i < localTimelogs.length; i++) {
        timelogs.push(localTimelogs[i]);
      }
      return data.project.issue.timelogs.pageInfo;
    });

    while (timelogsPageInfo.hasNextPage) {
      timelogsPageInfo = await this.graphqlRequest(
        moreTimelogsQuery(issueId, timelogsPageInfo.endCursor)
      ).then((data) => {
        const localTimelogs = data.project.issue.timelogs.nodes;
        for (let i = 0; i < localTimelogs.length; i++) {
          timelogs.push(localTimelogs[i]);
        }
        return data.project.issue.timelogs.pageInfo;
      });
    }

    return timelogs;
  }

  // pozor - pokud chci vsechny issues od projektu, jediny spolehlivy zpusob je dostatecne minule updatedAfter
  //         jinak by se mohli nektere starsi issues nenacist
  async getIssuesGraphQL(
    projectPath,
    onIssuesLoad,
    issueIid = null,
    updatedAfter = "2001-01-01"
  ) {
    const query = `{
          project(fullPath: "${projectPath}") {
            id
            issues(first: 100, updatedAfter: "${updatedAfter}" ${issueIid ? `, iid:"${issueIid}"` : ""
      }) {
              nodes {
                title
                iid
                id
                updatedAt
                closedAt
                assignees {
                  nodes {
                    name
                    username
                  }
                }
                notes(first: 100, filter: ONLY_ACTIVITY) {
                    nodes {
                        author {
                          name
                            username
                        }
                        id
                        body
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        }`;

    const moreIssuesQuery = (cursor) =>
      `{
          project(fullPath: "${projectPath}") {
            id
            issues(first: 100, updatedAfter: "${updatedAfter}", after: "${cursor}") {
              nodes {
                title
                iid
                updatedAt
                assignees {
                  nodes {
                    name
                    username
                  }
                }
                notes {
                    nodes {
                        author {
                          name
                            username
                        }
                        id
                        body
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
              }
              pageInfo {
                endCursor   
                hasNextPage
              }
            }
          }
        }`;

    const moreNotesQuery = (issueIid, cursor) =>
      `{
            project(fullPath: "${projectPath}") {
                issue(iid: "${issueIid}") {
                    notes(first: 100, after: "${cursor}", filter: ONLY_ACTIVITY) {
                        nodes {
                            author {
                              name
                                username
                            }
                            id
                            body
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                        } 
                    }
                }
            }
        }`;

    let loadedData = {};

    await this.graphqlRequest(query).then((data) => {
      loadedData = data;
    });

    // console.log(query)
    // console.log(loadedData)

    const issues = loadedData.project.issues.nodes;

    let issuesPageInfo = loadedData.project.issues.pageInfo;
    while (issuesPageInfo.hasNextPage) {
      issuesPageInfo = await this.graphqlRequest(
        moreIssuesQuery(issuesPageInfo.endCursor)
      ).then((data) => {
        const localIssues = data.project.issues.nodes;
        for (let i = 0; i < localIssues.length; i++) {
          issues.push(localIssues[i]);
        }
        return data.project.issues.pageInfo;
      });
    }

    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];

      issue.timelogs = {};
      issue.timelogs.nodes = await this.getAllTimelogsForIssue(projectPath, issue.iid);

      const notes = issue.notes.nodes;
      let notesPageInfo = issue.notes.pageInfo;

      while (notesPageInfo.hasNextPage) {
        notesPageInfo = await this.graphqlRequest(
          moreNotesQuery(issue.iid, notesPageInfo.endCursor)
        ).then((data) => {
          const localNotes = data.project.issue.notes.nodes;
          for (let i = 0; i < localNotes.length; i++) {
            notes.push(localNotes[i]);
          }
          return data.project.issue.notes.pageInfo;
        });
      }
    }

    onIssuesLoad(loadedData);
  }

  addSpentTime(projectId, issueIid, duration, date) {
    const endpoint = this.apiUrl(
      `/projects/${projectId}/issues/${issueIid}/notes?body=/spend ${duration} ${date}`
    );
    return this.ajaxRequest("POST", endpoint);
  }

  addComment(projectId, issueIid, comment) {
    const endpoint = this.apiUrl(
      `/projects/${projectId}/issues/${issueIid}/notes?body=${comment}`
    );
    return this.ajaxRequest("POST", endpoint);
  }

  async getIssueNotes(projectId, issueIid, authorIdFilter, onNotesLoad) {
    const notesEndpoint = this.apiUrl(
      `/projects/${projectId}/issues/${issueIid}/notes`
    );

    const onLoad = (data) => {
      onNotesLoad(
        data
          .filter((note) =>
            authorIdFilter !== null ? note.author.id === authorIdFilter : true
          )
          .map((note) => {
            return {
              id: note.id,
              issue: issueIid,
              project: projectId,
              body: note.body,
              author_name: note.author.name,
              author_username: note.author.username,
              author_id: note.author.id,
            };
          })
      );
    };

    await this.getPagedContent(notesEndpoint, onLoad, 50);
  }

  /* data:[]
  params: {
    minDate: number;
    maxDate: number;
    startDate: number;
    username?: string;
    cancelled:boolean
  }
  */
  loadReportData = async (data, params) => {
    return await this.getAllGroupProjects("czm").then(async (projects) => {
      const { minDate, maxDate, startDate, username, cancelled } = params;

      let startDateDayCode;

      /*
        Pokud je leden, tak je potreba se vyhnout nultemu mesici v datu, takze kdyz mame treba 17.1.2022,
        tak chceme 2021-12-17, a ne 2022-0-17, coz by nam vratila funkce dateToDayCode(startDate).
        U ostatnich mesicu to nevadi, protoze posun o mesic dozadu je realizovan normalne odectenim mesice.
       */
      if (startDate.getMonth() === 0) {
        startDateDayCode = new Date(startDate);
        startDateDayCode.setMonth(startDateDayCode.getMonth() - 1);
        startDateDayCode = `${startDateDayCode.getFullYear()}-${startDateDayCode.getMonth() + 1}-${startDateDayCode.getDate()}`;
      } else {
        startDateDayCode = dateToDayCode(startDate);
      }

      const usernameFilter = (usernameInput) => {
        if (!username) {
          return true;
        }
        return username === usernameInput;
      };

      if (!projects) {
        return;
      }

        return await Promise.all(
          projects.map(async (project) => {
            return this.getIssuesGraphQL(
              project.path,
              (projectData) => {
                const issues = projectData.project.issues.nodes;
                issues.forEach((issue) => {
                  if (cancelled) return;
                  const timelogs = issue.timelogs.nodes;
                  timelogs
                    .filter(({ user: { username } }) => usernameFilter(username))
                    .forEach(({ spentAt, timeSpent, user }) => {
                      if (cancelled) return;
                      const timeToAdd = timeSpent / (60 * 60);
                      const date = new Date(spentAt);
                      if (date >= minDate && date <= maxDate) {
                        data.push({
                          author_name: user.name,
                          author_id: user.id,
                          author_username: user.username,
                          project_id: project.id,
                          project_name: project.name,
                          project_path: project.path,
                          issue_title: issue.title,
                          issue_id: issue.id,
                          issue_iid: issue.iid,
                          date: date,
                          time_spent: timeToAdd,
                        });
                      }
                    });
                });
              },
              null,
              startDateDayCode
            );
          })
        );
    });
  };
}
