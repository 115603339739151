import React from "react";
import Reflux from "reflux";
import LoginActions from "../../actions/LoginActions";
import TokenStore from "../../stores/TokenStore";
import GitlabStore from "../../stores/GitlabStore";
import GitlabActions from "../../actions/GitlabActions";
import Gitlab from "../../gitlab";
import ProjectList from "./components/ProjectList";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import NewItemModal from "./components/Modal/NewItemModal";
import cs from "date-fns/locale/cs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSync,
  faSyncAlt,
  faTable,
  faTasks,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import IssuesModal from "./components/Modal/IssuesModal";
import { dayDiff } from "../../util";
import LoginInfo from "../components/LoginInfo";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import {Alert} from "react-bootstrap";

export default class DashboardPage extends Reflux.Component {
  static MODAL_NO_MODAL = 0;
  static MODAL_NEW_ITEM = 1;
  static MODAL_ISSUES = 2;

  /**
   *
   * @type {Gitlab}
   */
  gitlab = null;
  _mounted = false;
  objectsToLoad = 0;
  closedIssuesDataLoaded = {};
  filterTextTimeout = null;

  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      modal: DashboardPage.MODAL_NO_MODAL,
      issuesModalFocusTime: false,
      issuesModalFocusIssue: false,

      filterUserIssues: false,
      filterOpenedIssues: true,
      filterIssuesText: "",

      project: null,
      issue: null,
      date: null,
      loading: false,

      stateChanged: false,
    };

    this.stores = [TokenStore, GitlabStore];
  }

  incrementObjectsToLoad() {
    if (this._mounted) {
      this.objectsToLoad++;
      this.setState({ stateChanged: !this.state.stateChanged });
    }
  }

  decrementObjectsToLoad() {
    if (this._mounted) {
      this.objectsToLoad--;
      this.setState({ stateChanged: !this.state.stateChanged });
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.gitlab = new Gitlab(this.state.gitlabToken);

    this.setInitialReportTimespan();
    this.loadData();
  }

  setInitialReportTimespan() {
    let reportTimespan = null;
    try {
      reportTimespan = JSON.parse(localStorage.getItem("report_timespan"));
    } catch (e) {}

    if (reportTimespan != null) {
      if (reportTimespan.type === "user") {
        this.setState({
          startDate: new Date(reportTimespan.start),
          endDate: new Date(reportTimespan.end),
        });
      } else if (reportTimespan.type === "month") {
        this.showMonth();
      } else {
        this.showWeek();
      }
    } else {
      this.showWeek();
    }
  }

  saveReportTimespan(type) {
    try {
      const reportTimespan = {
        type: type,
        start: this.state.startDate.getTime(),
        end: this.state.endDate.getTime(),
      };

      localStorage.setItem("report_timespan", JSON.stringify(reportTimespan));
    } catch (e) {}
  }

  loadData() {
    const user = this.state.gitlabUsername;

    this.setState({ loading: true });
    this.gitlab.halt();
    this.closedIssuesDataLoaded = {};

    GitlabActions.onResetData();

    const load = () =>
      this.gitlab.getAllGroupProjects("czm").then(async (projects) => {
        if (projects && this._mounted) {
          GitlabActions.onProjectsLoad(projects);
          await Promise.all(
            projects.map(async (project) => {
              if(!project.isMyProject) return;
              return this.gitlab.getIssuesGraphQL(project.path, (issues) => {
                if (this._mounted) {
                  GitlabActions.onIssuesLoad(issues, user);
                }
              });
            })
          );
        }
      });

    load().then(() => {
      if (this._mounted) this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.gitlab.halt();
    this._mounted = false;
  }

  issueVisible(issueId) {
    this.setState({ stateChanged: !this.state.stateChanged });
  }

  issueHidden(issueId) {
    this.setState({ stateChanged: !this.state.stateChanged });
  }

  closeModal(myProjectsChanged) {
    this.setState({ modal: DashboardPage.MODAL_NO_MODAL });
    if(myProjectsChanged) this.loadData();
  }

  openNewItemModal(
    project,
    issue,
    date,
    focusTime = false,
    focusIssue = false
  ) {
    this.setState({
      modal: DashboardPage.MODAL_NEW_ITEM,
      project: project,
      issue: issue,
      date: date,
      issuesModalFocusTime: focusTime,
      issuesModalFocusIssue: focusIssue,
    });
  }

  openIssuesModal() {
    this.setState({ modal: DashboardPage.MODAL_ISSUES });
  }

  timeAdded(project, issue) {
    this.gitlab.getIssuesGraphQL(
      project.path,
      (issues) => {
        if (this._mounted) {
          GitlabActions.onIssuesLoad(issues, this.state.gitlabUsername);
          GitlabActions.onIssueVisibilitySet(issue.id, true);
          this.issueVisible(issue.id);
        }
      },
      issue.iid
    );
  }

  showWeek() {
    let today = new Date();
    today = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    );

    let dow = today.getDay() - 1;
    if (dow < 0) dow = 6;

    const monday = new Date(today.getTime());
    monday.setDate(monday.getDate() - dow);

    const sunday = new Date(monday.getTime());
    sunday.setDate(monday.getDate() + 6);

    this.setState({ startDate: monday, endDate: sunday });
    this.saveReportTimespan("week");
  }

  showMonth() {
    let today = new Date();
    today = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    );

    let start, end;
    let dom = today.getDate();

    if (dom < 18) {
      start = new Date(today.getTime());
      start.setMonth(start.getMonth() - 1);
      start.setDate(18);

      end = new Date(today.getTime());
      end.setDate(17);
    } else {
      start = new Date(today.getTime());
      start.setDate(18);

      end = new Date(today.getTime());
      end.setDate(17);
      end.setMonth(end.getMonth() + 1);
    }

    this.setState({ startDate: start, endDate: end });
    this.saveReportTimespan("month");
  }

  previousTimePeriod() {
    this.nextTimePeriod(-1);
  }

  nextTimePeriod(sign = 1) {
    let start = this.state.startDate,
      end = this.state.endDate;

    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    if (start.getDate() === end.getDate()) {
      // special case: month range
      const delta = sign;
      start.setMonth(start.getMonth() + delta);
      end.setMonth(end.getMonth() + delta);
    } else {
      //default case: day range
      const delta = dayDiff(start, end) * sign;
      start.setDate(start.getDate() + delta);
      end.setDate(end.getDate() + delta);
    }

    this.setState({ startDate: start, endDate: end });
    this.saveReportTimespan("user");
  }

  setFilterText(filterText) {
    if (this.filterTextTimeout !== null) {
      clearTimeout(this.filterTextTimeout);
    }

    this.filterTextTimeout = setTimeout(() => {
      this.setState({ filterIssuesText: filterText.trim() });
      this.filterTextTimeout = null;
    }, 300);
  }

  render() {
    let modal = null;

    switch (this.state.modal) {
      case DashboardPage.MODAL_NEW_ITEM:
        modal = (
          <NewItemModal
            visible={true}
            projects={this.state.projects}
            issues={this.state.issues}
            project={this.state.project}
            issue={this.state.issue}
            date={this.state.date}
            onClose={() => this.closeModal(false)}
            onSubmit={(project, issue) => this.timeAdded(project, issue)}
            gitlab={this.gitlab}
            focusTime={this.state.issuesModalFocusTime}
            focusIssue={this.state.issuesModalFocusIssue}
            username={this.state.gitlabUsername}
          />
        );
        break;

      case DashboardPage.MODAL_ISSUES:
        modal = (
          <IssuesModal
            visible={true}
            projects={this.state.projects}
            issues={this.state.issues}
            onClose={(myProjectsChanged) => this.closeModal(myProjectsChanged)}
            issueHidden={(issueId) => this.issueHidden(issueId)}
            issueVisible={(issueId) => this.issueVisible(issueId)}
            username={this.state.gitlabUsername}
          />
        );
        break;

      default:
        break;
    }

    const dashboard = (
      <div className="dashboard" key={0}>
        <h1>TimesUP!</h1>

        <div className="header">
          <Alert variant={"success"} className={"m-4 mr-5"}>
            Pokud se Vám nezobrazují projekty, které potřebujete, otevřete <b>Moje projekty</b> a přidejte si do <b>mých projektů</b> právě ty,
            do kterých si vykazujete práci. Čím víc <b>mých projektů</b> budete mít, tím delší bude načítání. :)
          </Alert>

          <div>
            <h5>Akce</h5>

            <Button
              variant="success"
              size="sm"
              onClick={() =>
                this.openNewItemModal(null, null, new Date(), false, false)
              }
            >
              <FontAwesomeIcon icon={faPlus} />
              Výkaz
            </Button>

            <Button
              variant="primary"
              size="sm"
              onClick={() => this.openIssuesModal()}
            >
              <FontAwesomeIcon icon={faTasks} />
              Moje projekty
            </Button>

            <Button
              variant="info"
              size="sm"
              onClick={() => LoginActions.onViewChange("personal-report")}
            >
              <FontAwesomeIcon icon={faUser} />
              Osobní report
            </Button>

            <Button
              variant="info"
              size="sm"
              onClick={() => LoginActions.onViewChange("report")}
            >
              <FontAwesomeIcon icon={faTable} />
              Reporty
            </Button>

            <Button
              variant="warning"
              disabled={this.state.loading || this.objectsToLoad !== 0}
              size="sm"
              onClick={() => this.loadData()}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
              Obnovit
            </Button>
          </div>

          <div>
            <h5>Období</h5>

            <div className="inline-form-group">
              <Form.Label htmlFor="start-date">Od</Form.Label>
              <DatePicker
                id="start-date"
                className="form-control date-input"
                locale={cs}
                dateFormat="d.M.yyyy"
                selected={this.state.startDate}
                onChange={(date) => {
                  this.setState({ startDate: date });
                  this.saveReportTimespan("user");
                }}
              />
            </div>

            <div className="inline-form-group">
              <Form.Label htmlFor="end-date">Do</Form.Label>
              <DatePicker
                id="end-date"
                className="form-control date-input"
                locale={cs}
                dateFormat="d.M.yyyy"
                selected={this.state.endDate}
                onChange={(date) => {
                  this.setState({ endDate: date });
                  this.saveReportTimespan("user");
                }}
              />
            </div>

            <div className="inline-form-group">
              <Button
                className="icon-button"
                variant="dark"
                size="sm"
                onClick={() => this.previousTimePeriod()}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button
                className="icon-button"
                variant="dark"
                size="sm"
                onClick={() => this.nextTimePeriod()}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => this.showWeek()}
              >
                Týden
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => this.showMonth()}
              >
                Měsíc
              </Button>
            </div>
          </div>

          <div className="dashboard-filter">
            <h5>Vyhledávání</h5>

            <div>
              <Form.Control
                id="dashboard-filter-search"
                type="text"
                placeholder="Zadejte název nebo ID hledané issue"
                onChange={(e) => this.setFilterText(e.target.value)}
              />
              {this.state.filterIssuesText.length > 0 ? (
                <span
                  className="clear-button"
                  onClick={() => {
                    this.setState({ filterIssuesText: "" });
                    document.querySelector("#dashboard-filter-search").value =
                      "";
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              ) : null}
            </div>

            <Form.Group
              controlId="dashboard-my-issues"
              className="checkbox-small-mr"
            >
              <Form.Check
                inline
                onChange={(e) =>
                  this.setState({ filterUserIssues: e.currentTarget.checked })
                }
              />
              <Form.Label>Jen mně přiřazené issues</Form.Label>
            </Form.Group>

            <span style={{ marginLeft: "5px", marginRight: "10px" }}>/</span>

            <Form.Group
              controlId="dashboard-opened-issues"
              className="checkbox-small-mr"
            >
              <Form.Check
                inline
                checked={this.state.filterOpenedIssues}
                onChange={(e) =>
                  this.setState({ filterOpenedIssues: e.currentTarget.checked })
                }
              />
              <Form.Label>Jen otevřené issues</Form.Label>
            </Form.Group>
          </div>
        </div>

        <div className="project-list">
          <ProjectList
            projects={this.state.projects}
            issues={this.state.issues}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            openNewItem={(
              project,
              issue,
              date,
              focusTime = false,
              focusIssue = false
            ) =>
              this.openNewItemModal(project, issue, date, focusTime, focusIssue)
            }
            timeSpent={this.state.timeSpent}
            filterText={this.state.filterIssuesText}
            filterUserIssues={this.state.filterUserIssues}
            filterOpenedIssues={this.state.filterOpenedIssues}
            username={this.state.gitlabUsername}
          />
        </div>

        {modal}

        <LoginInfo />
      </div>
    );

    const loadingBar =
      this.state.loading || this.objectsToLoad !== 0 ? (
        <div key={1} className="loading-bar">
          <span>
            <FontAwesomeIcon icon={faSync} /> Probíhá načítání dat ...
          </span>
        </div>
      ) : null;

    return [loadingBar, dashboard];
  }
}
