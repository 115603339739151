import React from 'react';
import LoginActions from "../../actions/LoginActions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoadingOverlay from 'react-loading-overlay';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";

export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        let savedToken = null;

        try {
            savedToken = JSON.parse(localStorage.getItem('gitlab_token'));
        } catch (e) {
        }

        this.state = {
            locked: false,
            loginFailed: false,
            gitlabToken: savedToken
        };
    }

    componentDidMount() {
        if (this.state.gitlabToken)
            this.login();
    }

    login() {
        if (!this.state.locked) {
            this.setState({locked: true});
            LoginActions.onLogin(this.state.gitlabToken, () => {
                this.setState({
                    locked: false,
                    loginFailed: true
                });
            });
        }
    }

    render() {
        return (
            <div className="login-screen">
                <h1>TimesUP!</h1>
                <h2>Elektronické vykazování práce</h2>

                <Form>
                    <Form.Group controlId="gitlab-token">
                        <Form.Label>Zadejte GitLab token</Form.Label>
                        <Form.Control disabled={this.state.locked} isInvalid={this.state.loginFailed} type="text"
                                      onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                              e.preventDefault();
                                              this.login();
                                          }
                                      }} onChange={e => this.setState({gitlabToken: e.target.value.trim()})}/>
                        <Form.Control.Feedback type="invalid">Token je neplatný</Form.Control.Feedback>
                    </Form.Group>

                    <div className="text-right">
                        <Button variant="success" disabled={this.state.locked} onClick={() => this.login()}>
                            <FontAwesomeIcon icon={faSignInAlt}/>Login
                        </Button>
                    </div>
                </Form>

                {
                    this.state.locked ?
                        <LoadingOverlay active={true} className="loader" spinner text="Přihlašování ..."/> :
                        null
                }
            </div>
        );
    }
}