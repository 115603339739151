import React from 'react';
import Reflux from 'reflux';

import LoginPage from "./pages/login";
import DashboardPage from "./pages/dashboard";
import TokenStore from "./stores/TokenStore";
import ReportPage from "./pages/report";
import PersonalReportPage from "./pages/personal-report";

export default class App extends Reflux.Component {
    constructor(props) {
        super(props);

        this.stores = [TokenStore];
    }

    render() {
        const loggedIn = Boolean(this.state.gitlabToken);
        const className = loggedIn ? "app" : "app login-background";
        let view;

        switch (this.state.view) {
            case "personal-report":
                view = <PersonalReportPage/>;
                break;

            case "report":
                view = <ReportPage/>;
                break;

            case "dashboard":
            default:
                view = <DashboardPage/>
        }

        return (
            <div className={className}>
                {loggedIn ? view : <LoginPage/>}
            </div>
        );
    }
}
