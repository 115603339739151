import Reflux from "reflux";
import LoginActions from "../actions/LoginActions";
import Gitlab from "../gitlab";

export default class TokenStore extends Reflux.Store {
  constructor() {
    super();

    this.state = {
      gitlabToken: null,
      gitlabName: "",
      gitlabUsername: "",
      gitlabUserId: 0,
      view: "dashboard",
    };

    this.listenables = LoginActions;
  }

  onLogin(gitlabToken, onError) {
    const gitlab = new Gitlab(gitlabToken);

    gitlab
      .getUserInfo()
      .then((userInfo) => {
        this.setState({
          gitlabToken: gitlabToken,
          gitlabName: userInfo.name,
          gitlabUsername: userInfo.username,
          gitlabUserId: userInfo.id,
        });

        try {
          localStorage.setItem("gitlab_token", JSON.stringify(gitlabToken));
        } catch (e) {
          // ignore error (most likely on safari)
        }
      })
      .catch(() => {
        onError();
      });
  }

  onLogout() {
    this.setState({
      gitlabToken: null,
      gitlabName: "",
      gitlabUserId: 0,
      view: "dashboard",
    });

    try {
      localStorage.setItem("gitlab_token", JSON.stringify(null));
    } catch (e) {
      // ignore error (most likely on safari)
    }
  }

  onViewChange(newView) {
    this.setState({ view: newView });
  }
}
