import React from 'react';
import Reflux from 'reflux';
import TokenStore from "../../stores/TokenStore";
import Button from "react-bootstrap/Button";
import LoginActions from "../../actions/LoginActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";

export default class LoginInfo extends Reflux.Component {
    constructor(props) {
        super(props);

        this.stores = [TokenStore];
    }

    render() {
        return(
            <div className="login-info">
                <span>{this.state.gitlabName}</span>
                <Button variant="link" className="pseudobutton" onClick={() => LoginActions.onLogout()}>
                    <FontAwesomeIcon icon={faSignOutAlt}/>Logout
                </Button>
            </div>
        );
    }
}