import React from "react";
import Modal from "../Modal/Modal";
import Form from "react-bootstrap/Form";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap/Button";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import cs from "date-fns/locale/cs";
import {Portal} from 'react-overlays';
import CloseButton from "./CloseButton";

export default class NewItemModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterUserIssues: false,
            filterOpenedIssues: true,
            comment: "",

            project: props.project,
            issue: props.issue,
            date: props.date,
            hours: 0,
            minutes: 0,
            sign: 1,

            submitted: false,
            hoursInvalid: false,
            minutesInvalid: false,
            dateInvalid: false,
            nulltime: false
        };
    }

    datepickerContainer({children}) {
        return (
            <Portal>
                {children}
            </Portal>
        );
    }

    validate() {
        const validateNumber = number => {
            if (!number || number.length < 1)
                return true;

            const numberRegex = /\d+(\.\d+)?/;
            const expr = numberRegex.exec(number);
            return expr && expr[0] === number;
        };

        const hoursValid = validateNumber(this.state.hours);
        const minutesValid = validateNumber(this.state.minutes);
        const dateValid = (this.state.date && true);

        this.setState({
            submitted: true,
            hoursInvalid: !hoursValid,
            minutesInvalid: !minutesValid,
            dateInvalid: !dateValid
        });

        return this.state.project && this.state.issue && this.state.date && hoursValid && minutesValid && dateValid;
    }

    submit() {
        if (this.validate()) {
            const hours = parseFloat(this.state.hours), minutes = parseFloat(this.state.minutes);

            if (hours > 0 || minutes > 0) {
                let comment = this.state.comment.trim();

                if (comment.length > 0) {
                    comment = encodeURI(comment);
                    this.props.gitlab.addComment(this.state.project.id, this.state.issue.iid, comment).then(() => {
                        // Only add spent time after adding comment succeeds
                        this.addSpentTime(hours, minutes);
                    }, () => {
                        const ajaxErrorWrapper = {
                            "comment": decodeURI(comment),
                            "projectID": this.state.project.id,
                            "issueIID": this.state.issue.iid
                        };

                        this.saveErrorToLocalStorage(ajaxErrorWrapper,"Nastala chyba při odesílání komentáře na GitLab. Chybové hlášení je uloženo v local storage.");
                    });
                } else {
                    this.addSpentTime(hours, minutes);
                }
            } else {
                this.setState({nulltime: true});
            }
        }
    }

    addSpentTime(hours, minutes) {
        let date = this.state.date;
        date = `${date.getFullYear()}-${1 + date.getMonth()}-${date.getDate()}`;

        const sign = this.state.sign === -1 ? "-" : "";
        const timeExpr = `${sign} ${hours}h ${minutes}m`;
        const comment = this.state.comment;

        this.props.gitlab.addSpentTime(this.state.project.id, this.state.issue.iid, timeExpr, date).then(() => {
            this.props.onSubmit(this.state.project, this.state.issue);
            this.props.onClose();
        }, (error) => {
            const ajaxErrorWrapper = {
                "message": error,
                "hours": hours,
                "minutes": minutes,
                "sign": sign,
                "comment": comment,
                "timeExpired": timeExpr,
                "date": date,
                "projectID": this.state.project.id,
                "issueIID": this.state.issue.iid
            }

            this.saveErrorToLocalStorage(ajaxErrorWrapper, "Nastala chyba při vykazování hodin na GitLab. Chybové hlášení je uloženo v local storage.");
        });
    }

    saveErrorToLocalStorage(ajaxErrorWrapper, message) {
        window.alert(message);
        let now = new Date();
        now = now.toISOString().slice(0, 10).replaceAll('-', '_') + '_' + now.getHours() + "_" + now.getMinutes() + "_" + now.getSeconds()
        localStorage.setItem("error_" +  now, JSON.stringify(ajaxErrorWrapper));
    }

    filterIssuesFun(issue) {
        return this.state.project &&
            issue.project === this.state.project.id &&
            (!this.state.filterUserIssues || issue.assignee === this.props.username) &&
            (!this.state.filterOpenedIssues || !issue.closed);
    }

    render() {
        return (
            <Modal visible={this.props.visible} title="Výkaz" style={{minWidth: "500px"}} onClose={this.props.onClose}>
                <CloseButton onClick={this.props.onClose}/>

                <Form>
                    <Form.Group controlId="new-item-project">
                        <Form.Label>Projekt*</Form.Label>
                        <Typeahead id="new-item-project" filterBy={(option, props) => {
                            return props.selected.length ? true : option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                        }} flip clearButton highlightOnlyResult selectHintOnEnter positionFixed
                                   isInvalid={this.state.submitted && !this.state.project}
                                   selected={this.state.project ? [this.state.project] : []}
                                   options={this.props.projects} labelKey={"name"} emptyLabel="Nebyl nalezen žádný projekt"
                                   placeholder="Vyberte projekt"
                                   onChange={items => this.setState({project: items[0], issue: null})}/>
                    </Form.Group>

                    <Form.Group controlId="new-item-issue">
                        <Form.Label>Issue*</Form.Label>
                        <Typeahead isInvalid={this.state.submitted && !this.state.issue}
                                   id="new-item-issue"
                                   filterBy={(option, props) => {
                                       return props.selected.length ? true : `#${option.iid} ${option.title}`
                                           .toLowerCase()
                                           .indexOf(props.text.toLowerCase()) !== -1;
                                   }}
                                   flip clearButton highlightOnlyResult selectHintOnEnter positionFixed
                                   selected={this.state.issue ? [this.state.issue] : []}
                                   onChange={items => this.setState({issue: items[0]})}
                                   options={this.props.issues.filter(issue => this.filterIssuesFun(issue))}
                                   labelKey={option => `#${option.iid}: ${option.title}`}
                                   emptyLabel="Nebyly nalezeny žádné issues"
                                   placeholder="Vyberte issue"
                                   renderMenuItemChildren={(option, props, index) => `#${option.iid}: ${option.title}`}
                                   autoFocus={this.props.focusIssue}
                        />
                    </Form.Group>

                    <Form.Group className="text-center">
                        <Form.Check id="checkbox-my-issues" style={{margin: "0"}} inline onChange={e => this.setState({filterUserIssues: e.currentTarget.checked})} />
                        <Form.Label htmlFor="checkbox-my-issues">Jen mně přiřazené issues</Form.Label>

                        <span style={{marginLeft: "5px", marginRight: "10px"}}>/</span>

                        <Form.Check id="checkbox-opened-issues" style={{margin: "0"}} inline checked={this.state.filterOpenedIssues} onChange={e => this.setState({filterOpenedIssues: e.currentTarget.checked})} />
                        <Form.Label htmlFor="checkbox-opened-issues">Jen otevřené issues</Form.Label>
                    </Form.Group>

                    <Form.Group controlId="new-item-date">
                        <Form.Label>Datum*</Form.Label>
                        <div style={{display: "block"}}>
                            <DatePicker id="new-item-date" locale={cs}
                                        className={"form-control date-input " + (this.state.dateInvalid ? "is-invalid" : "")}
                                        dateFormat="d.M.yyyy" selected={this.state.date}
                                        maxDate={new Date()}
                                        onChange={date => {
                                            const valid = date && true;
                                            this.setState({date: date, dateInvalid: !valid});
                                        }}
                                        popperContainer={this.datepickerContainer}/>
                        </div>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="new-item-hours">Odpracovaný čas*</Form.Label>

                        <div className="modal-form-inline-container">
                            <Form.Check inline checked={this.state.sign === 1} onChange={() => this.setState({sign: this.state.sign * -1})} id="new-item-add" name="operation" type="radio" label="Přidat" />
                            <Form.Check inline checked={this.state.sign === -1} onChange={() => this.setState({sign: this.state.sign * -1})} id="new-item-subtract" name="operation" type="radio" label="Odebrat" />
                        </div>

                        <div style={{position: "relative"}}>
                            <Form.Control type="text" className="time-input" placeholder="0" id="new-item-hours"
                                          onChange={e => this.setState({hours: e.target.value.trim(), nulltime: false})}
                                          autoFocus={this.props.focusTime}
                                          onKeyPress={e => {
                                              if (e.key === 'Enter') {
                                                  e.preventDefault();
                                                  this.submit();
                                              }
                                          }}
                                          isInvalid={this.state.submitted && this.state.hoursInvalid}/>
                            {!this.state.nulltime ? null :
                                <div className="invalid-tooltip" style={{display: "block", position: "absolute"}}>
                                    Není vyplněn čas
                                </div>
                            }
                            h

                            <Form.Control type="text" className="time-input" placeholder="0"
                                          onChange={e => {
                                              let newMinutes = e.target.value.trim();
                                              this.setState({minutes: newMinutes.length === 0 ? '0' : newMinutes, nulltime: false});
                                          }}
                                          onKeyPress={e => {
                                              if (e.key === 'Enter') {
                                                  e.preventDefault();
                                                  this.submit();
                                              }
                                          }}
                                          isInvalid={this.state.submitted && this.state.minutesInvalid}/>
                            m
                        </div>
                    </Form.Group>

                    <Form.Group controlId="new-item-comment">
                        <Form.Label>Komentář</Form.Label>
                        <Form.Control as="textarea" onChange={e => this.setState({comment: e.target.value})}/>
                    </Form.Group>

                    <div className="text-right modal-controls">
                        <Button variant="success" onClick={() => this.submit()}>
                            <FontAwesomeIcon icon={faCheck}/>Potvrdit
                        </Button>
                        <Button variant="danger" onClick={this.props.onClose}>
                            <FontAwesomeIcon icon={faTimes}/>Zrušit
                        </Button>
                    </div>
                </Form>
            </Modal>
        );
    }
};