import React from "react";

export default class Modal extends React.Component {
    render() {
        return !this.props.visible ? null : (
            <div className={"modal " + this.props.className} onMouseDown={e => {
                if (e.target === e.currentTarget && this.props.onClose)
                    this.props.onClose();
            }}>
                <div className="modal-content" style={this.props.style}>
                    <h3 className="modal-title">{this.props.title}</h3>
                    {this.props.children}
                </div>
            </div>
        );
    }
};