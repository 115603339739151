import React from "react";
import Modal from "./Modal";
import Form from "react-bootstrap/Form";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from "react-bootstrap/Button";
import {
    faCheck,
    faEye,
    faEyeSlash,
    faVectorSquare,
    faBan,
    faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import GitlabActions from "../../../../actions/GitlabActions";
import CloseButton from "./CloseButton";
import {Alert} from "react-bootstrap";

export default class IssuesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: null,
            search: "",
            filterUserIssues: false,
            filterOpenedIssues: true,
            myProjectsChanged: false,
            stateChanged: false
        };
    }

    toggleVisibility(issue) {
        issue.visible = !issue.visible;
        this.props.issues[issue.id].visible = !this.props.issues[issue.id].visible;
        GitlabActions.onIssueVisibilityToggled(issue.id);

        if (issue.visible)
            this.props.issueVisible(issue.id);
        else
            this.props.issueHidden(issue.id);

        this.setState({stateChanged: !this.state.stateChanged});
    }

    getFilteredIssues() {
        return this.props.issues.filter(issue =>
            this.state.project &&
            issue.project === this.state.project.id &&
            (!this.state.filterUserIssues || issue.assignee === this.props.username) &&
            (!this.state.filterOpenedIssues || !issue.closed) &&
            `#${issue.iid} ${issue.title}`.toLowerCase().indexOf(this.state.search) !== -1)
    }

    showAll() {
        this.getFilteredIssues().forEach(issue => {
            if (!issue.visible)
                this.toggleVisibility(issue);
        });
    }

    hideAll() {
        this.getFilteredIssues().forEach(issue => {
            if (issue.visible)
                this.toggleVisibility(issue);
        });
    }

    invertVisibility() {
        this.getFilteredIssues().forEach(issue => this.toggleVisibility(issue));
    }

    toggleMyProject(project) {
        GitlabActions.onMyProjectToggled(project.id);

        if (!project.isMyProject) {
            //this.props.issues.forEach(issue => {
            //    if (issue.project === projectId)
            //        this.props.issueHidden(issue.id);
            //});

            this.props.issueHidden(-1);
        } else {
            this.props.issues.forEach(issue => {
                if (issue.project === project.id)
                    this.props.issueVisible(issue.id);
            });
        }
    }

    toggleCurrentMyProject() {
        this.toggleMyProject(this.state.project);

        this.setState({stateChanged: !this.state.stateChanged, myProjectsChanged: true});
    }

    removeAllFromMyProjects() {
        this.props.projects.forEach(project => {if (project.isMyProject) this.toggleMyProject(project)});
        this.setState({stateChanged: !this.state.stateChanged, myProjectsChanged: true});
    }

    addAllToMyProjects() {
        this.props.projects.forEach(project => {if (!project.isMyProject) this.toggleMyProject(project)});
        this.setState({stateChanged: !this.state.stateChanged, myProjectsChanged: true});
    }

    invertMyProjects() {
        this.props.projects.forEach(project => this.toggleMyProject(project));
        this.setState({stateChanged: !this.state.stateChanged, myProjectsChanged: true});
    }

    render() {
        const openedIssues = this.getFilteredIssues();

        return (
            <Modal visible={this.props.visible} title="Moje projekty" style={{minWidth: "500px"}} onClose={() => this.props.onClose(this.state.myProjectsChanged)} className="issues-modal">
                <CloseButton onClick={() => this.props.onClose(this.state.myProjectsChanged)}/>

                <Form>
                    <Form.Group controlId="issues-project">
                        <Form.Label>Projekt</Form.Label>
                        <Typeahead id="issues-project" placeholder="Vyberte projekt" filterBy={(option, props) => {
                            return props.selected.length ? true : option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                        }} flip clearButton highlightOnlyResult selectHintOnEnter positionFixed
                                   selected={this.state.project ? [this.state.project] : []}
                                   options={this.props.projects} labelKey={option => `${option.name} ${option.isMyProject ? "[MŮJ PROJEKT]" : ""}`}
                                   onChange={items => this.setState({project: items[0], issue: null})}/>
                    </Form.Group>

                    <div className="text-center">
                        {
                            !!this.state.project ? (
                            this.state.project.isMyProject ?
                                <span className="btn btn-link pseudobutton"
                                      onClick={() => this.toggleCurrentMyProject()}>
                                                        <FontAwesomeIcon icon={faBan} /> Odebrat z mých projektů
                                </span>
                                :
                                <span className="btn btn-link pseudobutton"
                                      onClick={() => this.toggleCurrentMyProject()}>
                                                        <FontAwesomeIcon icon={faCheckCircle}/> Přidat do mých projektů
                                </span>
                            )
                                :
                                null
                        }
                    </div>

                    <div className="text-center">
                        <span className="btn btn-link pseudobutton"
                              onClick={() => this.removeAllFromMyProjects()}>
                            <FontAwesomeIcon icon={faBan} /> Odebrat vše z mých projektů
                        </span>

                        <span className="btn btn-link pseudobutton"
                              onClick={() => this.addAllToMyProjects()}>
                            <FontAwesomeIcon icon={faCheckCircle}/> Přidat vše do mých projektů
                        </span>

                        <span className="btn btn-link pseudobutton"
                              onClick={() => this.invertMyProjects()}>
                            <FontAwesomeIcon icon={faVectorSquare}/> Invertovat moje projekty
                        </span>
                    </div>

                    <Form.Group controlId="issues-search">
                        <Form.Label>Hledat</Form.Label>
                        <Form.Control type="text" placeholder="Zadejte hledaný výraz"
                                      onChange={e => this.setState({search: e.target.value.trim().toLowerCase()})}/>
                    </Form.Group>

                    <div>
                        <div className="issue-list">
                            {
                                (openedIssues.length > 0 && this.state.project.isMyProject) || this.state.filterUserIssues ?
                                    <ul>
                                        <li key={-2}>Dostupné issues</li>
                                        <li key={-1} className="text-center">
                                            <span className="btn btn-link pseudobutton"
                                                  onClick={() => this.showAll()}><FontAwesomeIcon icon={faEye}/> Zobrazit vše</span>
                                            <span className="btn btn-link pseudobutton"
                                                  onClick={() => this.hideAll()}><FontAwesomeIcon icon={faEyeSlash}/> Skrýt vše</span>
                                            <span className="btn btn-link pseudobutton"
                                                  onClick={() => this.invertVisibility()}><FontAwesomeIcon
                                                icon={faVectorSquare}/> Invertovat výběr</span>
                                        </li>
                                        <li key={0} className="text-center">
                                            <Form.Group className="text-center">
                                                <Form.Check id="checkbox-my-issues" style={{margin: "0"}} inline onChange={e => this.setState({filterUserIssues: e.currentTarget.checked})} />
                                                <Form.Label htmlFor="checkbox-my-issues">Jen mně přiřazené issues</Form.Label>

                                                <span style={{marginLeft: "5px", marginRight: "10px"}}>/</span>

                                                <Form.Check id="checkbox-opened-issues" style={{margin: "0"}} inline checked={this.state.filterOpenedIssues} onChange={e => this.setState({filterOpenedIssues: e.currentTarget.checked})} />
                                                <Form.Label htmlFor="checkbox-opened-issues">Jen otevřené issues</Form.Label>
                                            </Form.Group>
                                        </li>

                                        {
                                            openedIssues.map(issue =>
                                                <li key={issue.id} onClick={() => this.toggleVisibility(issue)}
                                                    className="btn btn-link pseudobutton">
                                                    {issue.visible ?
                                                        <FontAwesomeIcon icon={faEye}/> :
                                                        <FontAwesomeIcon icon={faEyeSlash}/>}

                                                    #{issue.iid}: {issue.title}
                                                </li>
                                            )
                                        }
                                    </ul> :
                                    <em>
                                        {
                                            this.state.project ?
                                            (
                                                this.state.project.isMyProject ?
                                                "Žádné dostupné issues"
                                                :
                                                "Vybraný projekt není v mých projektech"
                                            )
                                            :
                                            "Není vybrán žádný projekt"
                                        }
                                    </em>
                            }
                        </div>
                    </div>

                    <div className="text-right modal-controls">
                        <Button variant="primary" onClick={() => this.props.onClose(this.state.myProjectsChanged)}>
                            <FontAwesomeIcon icon={faCheck}/>Hotovo
                        </Button>
                    </div>
                </Form>
                {this.state.myProjectsChanged ?
                    <Alert variant={"info"}>
                       Po zavření okna se všechna data znovu načtou.
                    </Alert>
                    :
                    <Alert variant={"warning"}>
                        Pokud provedete změny <b>mých projektů</b>, všechna data se po zavření okna znovu načtou.
                    </Alert>
                }

            </Modal>
        );
    }
};
